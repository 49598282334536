import { useRouter } from 'next/router'
import clsx from 'clsx'

import NotFoundSvg from '@/assets/svgs/not_found.svg'
import { textShadow } from '@/styles/utils'
import { goBack } from '@/utils'

import { PATH } from '@/constants/path'

import { ArrowLeftIcon } from '@/components/Icons'
import BaseLayout from '@/components/Layout'
import Link from 'next/link'

export default function NotFoundPage() {
  const router = useRouter()

  return (
    <BaseLayout
      header={{
        title: <></>,
        leftButton: <ArrowLeftIcon />,
        leftButtonProps: {
          onClick: () => goBack(router, router.pathname, PATH.home),
        },
        bottomBorder: false,
      }}
    >
      <div
        className={clsx(
          'flex',
          'flex-col',
          'items-center',
          'space-y-[100px]',
          'pt-[50px]',
        )}
      >
        <div
          className={clsx('font-DespairTime', 'text-[20px]', 'font-[400]')}
          style={{ ...textShadow('0.3px') }}
        >
          Sorry...
        </div>
        <div className={clsx('w-full', 'max-w-[500px]')}>
          <NotFoundSvg />
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="font-bold text-3xl">존재하지 않는 페이지입니다.</div>
          <br />
          <Link href={PATH.home} replace>
            홈으로 가기
          </Link>
        </div>
      </div>
    </BaseLayout>
  )
}
